import { useTranslation } from 'react-i18next';
import SubMenuBox from '../../SubMenuBox';
import SubMenuBoxAnchorLink from '../../SubMenuBoxAnchorLink';
// import SubMenuDescriptionBox from './SubMenuDescriptionBox';
import DropdownMenuItem from './DropdownMenuItem';
import GlobalSettings from '../GlobalSettings';
import ContactCard from '../../ContactCard';
import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import logo_full_name from '../../../images/logo/rdct.webp';
import aboutImage from '../../../images/about/fullstack-web-development-bonaberi-douala-cameroon-cameroun-mambanda.webp';
import TrainingWebDevelopmentFrontendImage from '../../../images/training-courses/web-development-frontend/training-web-development-frontend-html-css-javascript-react.webp';
import TrainingWebDevelopmentBackendImage from '../../../images/training-courses/web-develpment-backend/training-web-development-backend-mysql-database.webp';
import ManagementImage from '../../../images/research-fields/rdct-cameroun-cameroon-douala-bonaberi-research-center-fields-management-business-people.webp';
import TrainingSearchEngineOptimizationImage from '../../../images/training-courses/search-engine-optimization/rdct-education-center-training-courses-web-analytics.webp';
import TrainingMicrosoftOfficeImage from '../../../images/training-courses/microsoft-office/training-formation-microsoft-office-secretariat-bureautique-word-excel-powerpoint.webp';
import TrainingDigitalMarketingImage from '../../../images/training-courses/digital_marketing/digital-community-social-media-management-email-sms-marketing-techniques.webp';
// import DigitalMarketingImage from '../../../images/research-fields/rdct-cameroun-cameroon-douala-bonaberi-research-center-fields-digital-marketing.webp';
// import WebTechnologieImage from '../../../images/research-fields/rdct-cameroun-cameroon-douala-bonaberi-research-center-fields-web-technologies.webp';
import MenuCleanImage from '../../../images/solutions/agence-clean-menageres-nounous-recrutement-recherche-menage-service-domicile-homme-femme-menage-agents-entretien.webp';
import MenuBookSharingImage from '../../../images/solutions/cameroun-douala-book-sharing.webp';
import solutionsCenterImg from '../../../images/home/rdct-solutions-center.webp'
// import MicrosoftWordImage from '../../../images/training-courses/rdct-education-center-training-courses-microsoft-office-word.webp';
// import InternshipDefinitionImage from '../../../images/internship-program/internship-program-definition.webp';

import * as BsIcons from "react-icons/bs";
import * as GiIcons from "react-icons/gi";
import * as MdIcons from "react-icons/md";

const MainNavigation = styled.nav`
    background-color: ${GlobalSettings.mainDarkColor};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    padding-top: 5px;
    padding-bottom: 5px;

    padding-right: 64px;
    padding-left: 64px;

    @media screen and (max-width: 1200px) {
        display: none; 
    }
`;

const MenuWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const SimpleMenuLink = styled(Link)`
  color: ${GlobalSettings.mainWhiteColor};
  padding: ${GlobalSettings.marginAroundHTMLElements};
  margin-top: 0;
  margin-bottom: 0;
  text-decoration: none;
  &:hover {
    color: ${GlobalSettings.mainYellowColor};
    cursor: pointer;
  }
`;

const SubMenuBoxes = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;
    flex-wrap: wrap;
    padding: ${GlobalSettings.paddingAroundHTMLElements};
    row-gap: ${GlobalSettings.spaceAroundHTMLElements};
    column-gap: ${GlobalSettings.spaceAroundHTMLElements};
`;

const DropdownMenuItemContainer = styled.div`
    display: flex;
    flex-direction: row;
    // max-width: 1400px;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;

    padding-right: 20px;
    padding-left: 20px;
`;

const LogoWrapper = styled(Link)`
    transition: opacity 0.2s ease;
    :hover {
        cursor: pointer;
        opacity: 0.6;
    }
`;

const LogoImage = styled.img`
    height: 55px;
    width: 55px;
`;

const LanguageButton = styled.button`
    background-color: ${GlobalSettings.mainYellowColor};
    color: ${GlobalSettings.mainWhiteColor};
    padding: 10px;
    margin-top: 0;
    margin-bottom: 0;
    border-color: transparent;
    /* transition: ease background-color 0.25s; */ 
    border-radius:50px;
    &:hover {
      color: ${GlobalSettings.mainDarkColor};
      background-color: ${GlobalSettings.mainWhiteColor};
      cursor: pointer;
    }
`;

const contactsData = [
    {
        cardColor: GlobalSettings.mainDarkColor,
        textColor: GlobalSettings.surrogateWhiteColor, 
        description: 'Google map', 
        icons: <GiIcons.GiPositionMarker size='2em' color={GlobalSettings.mainYellowColor}/>,
    },
    {
        cardColor: GlobalSettings.mainDarkColor,
        textColor: GlobalSettings.surrogateWhiteColor, 
        description: '(+237) 682634355', 
        icons: <BsIcons.BsFillTelephoneFill size='2em' color={GlobalSettings.mainYellowColor}/>,
    },
    {
        cardColor: GlobalSettings.mainDarkColor,
        textColor: GlobalSettings.surrogateWhiteColor, 
        description: '(+237) 694060524', 
        icons: <BsIcons.BsFillTelephoneFill size='2em' color={GlobalSettings.mainYellowColor}/>,
    },
    {
        cardColor: GlobalSettings.mainDarkColor,
        textColor: GlobalSettings.surrogateWhiteColor, 
        description: '(+237) 233392501', 
        icons: <BsIcons.BsFillTelephoneFill size='2em' color={GlobalSettings.mainYellowColor}/>,
    },
    {
        cardColor: GlobalSettings.mainDarkColor,
        textColor: GlobalSettings.surrogateWhiteColor, 
        description: 'info@rdct.tech', 
        icons: <MdIcons.MdOutlineMailOutline size='2em' color={GlobalSettings.mainYellowColor}/>,
    }
];

const HeaderDesktop = () => {

    const {t, i18n} = useTranslation('header_footer');

    /*
    function ToggleLanguage(language_code) {
        localStorage.setItem("i18nextLng", language_code);
        i18n.changeLanguage(language_code);

        if (window.location.pathname === "/en/home" || window.location.pathname === "/fr/accueil") {
            if (window.location.pathname.startsWith(`/en/`) && language_code === 'fr') {
                window.location.pathname = "/fr/accueil" ;
            }
            else if (window.location.pathname.startsWith(`/fr/`) && language_code === 'en'){
                window.location.pathname = "/en/home";
            }
        }
        else if (window.location.pathname === "/en/about-the-rdct" || window.location.pathname === "/fr/a-propos-du-rdct") {
            if (window.location.pathname.startsWith(`/en/`) && language_code === 'fr') {
                window.location.pathname = "/fr/a-propos-du-rdct" ;
            }
            else if (window.location.pathname.startsWith(`/fr/`) && language_code === 'en'){
                window.location.pathname = "/en/about-the-rdct";
            }
        }
        else if (window.location.pathname === "/en/history" || window.location.pathname === "/fr/histoire") {
            if (window.location.pathname.startsWith(`/en/`) && language_code === 'fr') {
                window.location.pathname = "/fr/histoire" ;
            }
            else if (window.location.pathname.startsWith(`/fr/`) && language_code === 'en'){
                window.location.pathname = "/en/history";
            }
        }
        else if (window.location.pathname === "/en/research-web-technologies" || window.location.pathname === "/fr/recherche-technologies-web") {
            if (window.location.pathname.startsWith(`/en/`) && language_code === 'fr') {
                window.location.pathname = "/fr/recherche-technologies-web" ;
            }
            else if (window.location.pathname.startsWith(`/fr/`) && language_code === 'en'){
                window.location.pathname = "/en/research-web-technologies";
            }
        }
        else if (window.location.pathname === "/en/research-digital-marketing" || window.location.pathname === "/fr/recherche-marketing-digitale") {
            if (window.location.pathname.startsWith(`/en/`) && language_code === 'fr') {
                window.location.pathname = "/fr/recherche-marketing-digitale" ;
            }
            else if (window.location.pathname.startsWith(`/fr/`) && language_code === 'en'){
                window.location.pathname = "/en/research-digital-marketing";
            }
        }
        else if (window.location.pathname === "/en/research-management" || window.location.pathname === "/fr/recherche-management") {
            if (window.location.pathname.startsWith(`/en/`) && language_code === 'fr') {
                window.location.pathname = "/fr/recherche-management" ;
            }
            else if (window.location.pathname.startsWith(`/fr/`) && language_code === 'en'){
                window.location.pathname = "/en/research-management";
            }
        }
        else if (window.location.pathname === "/en/internship-program" || window.location.pathname === "/fr/programme-stages") {
            if (window.location.pathname.startsWith(`/en/`) && language_code === 'fr') {
                window.location.pathname = "/fr/programme-stages" ;
            }
            else if (window.location.pathname.startsWith(`/fr/`) && language_code === 'en'){
                window.location.pathname = "/en/internship-program";
            }
        }
        else if (window.location.pathname === "/en/training-web-development-frontend" || window.location.pathname === "/fr/formation-developpement-web-frontend") {
            if (window.location.pathname.startsWith(`/en/`) && language_code === 'fr') {
                window.location.pathname = "/fr/formation-developpement-web-frontend" ;
            }
            else if (window.location.pathname.startsWith(`/fr/`) && language_code === 'en'){
                window.location.pathname = "/en/training-web-development-frontend";
            }
        }
        else if (window.location.pathname === "/en/training-web-development-backend" || window.location.pathname === "/fr/formation-developpement-web-backend") {
            if (window.location.pathname.startsWith(`/en/`) && language_code === 'fr') {
                window.location.pathname = "/fr/formation-developpement-web-backend" ;
            }
            else if (window.location.pathname.startsWith(`/fr/`) && language_code === 'en'){
                window.location.pathname = "/en/training-web-development-backend";
            }
        }
        else if (window.location.pathname === "/en/training-search-engine-optimization" || window.location.pathname === "/fr/formation-search-engine-optimization") {
            if (window.location.pathname.startsWith(`/en/`) && language_code === 'fr') {
                window.location.pathname = "/fr/formation-search-engine-optimization" ;
            }
            else if (window.location.pathname.startsWith(`/fr/`) && language_code === 'en'){
                window.location.pathname = "/en/training-search-engine-optimization";
            }
        }
        else if (window.location.pathname === "/en/training-digital-marketing" || window.location.pathname === "/fr/formation-marketing-digitale") {
            if (window.location.pathname.startsWith(`/en/`) && language_code === 'fr') {
                window.location.pathname = "/fr/formation-marketing-digitale" ;
            }
            else if (window.location.pathname.startsWith(`/fr/`) && language_code === 'en'){
                window.location.pathname = "/en/training-digital-marketing";
            }
        }
        else if (window.location.pathname === "/en/training-microsoft-office" || window.location.pathname === "/fr/formation-microsoft-office") {
            if (window.location.pathname.startsWith(`/en/`) && language_code === 'fr') {
                window.location.pathname = "/fr/formation-microsoft-office" ;
            }
            else if (window.location.pathname.startsWith(`/fr/`) && language_code === 'en'){
                window.location.pathname = "/en/training-microsoft-office";
            }
        }
        else if (window.location.pathname === "/en/consulting-services" || window.location.pathname === "/fr/services-conseil") {
            if (window.location.pathname.startsWith(`/en/`) && language_code === 'fr') {
                window.location.pathname = "/fr/services-conseil" ;
            }
            else if (window.location.pathname.startsWith(`/fr/`) && language_code === 'en'){
                window.location.pathname = "/en/consulting-services";
            }
        }
        else if (window.location.pathname === "/en/support-for-visa" || window.location.pathname === "/fr/aide-pour-visa") {
            if (window.location.pathname.startsWith(`/en/`) && language_code === 'fr') {
                window.location.pathname = "/fr/aide-pour-visa" ;
            }
            else if (window.location.pathname.startsWith(`/fr/`) && language_code === 'en'){
                window.location.pathname = "/en/support-for-visa";
            }
        }
        else if (window.location.pathname === "/en/terms-of-use" || window.location.pathname === "/fr/politique-utilisation") {
            if (window.location.pathname.startsWith(`/en/`) && language_code === 'fr') {
                window.location.pathname = "/fr/politique-utilisation" ;
            }
            else if (window.location.pathname.startsWith(`/fr/`) && language_code === 'en'){
                window.location.pathname = "/en/terms-of-use";
            }
        }
        else if (window.location.pathname === "/en/privacy-statements" || window.location.pathname === "/fr/politique-donnees") {
            if (window.location.pathname.startsWith(`/en/`) && language_code === 'fr') {
                window.location.pathname = "/fr/politique-donnees" ;
            }
            else if (window.location.pathname.startsWith(`/fr/`) && language_code === 'en'){
                window.location.pathname = "/en/privacy-statements";
            }
        }
        else if (window.location.pathname === "/en/cookie-statements" || window.location.pathname === "/fr/politique-cookies") {
            if (window.location.pathname.startsWith(`/en/`) && language_code === 'fr') {
                window.location.pathname = "/fr/politique-cookies" ;
            }
            else if (window.location.pathname.startsWith(`/fr/`) && language_code === 'en'){
                window.location.pathname = "/en/cookie-statements";
            }
        }

    }*/

    function ToggleLanguage(language_code) {
        localStorage.setItem("i18nextLng", language_code);
        i18n.changeLanguage(language_code);
    }

    return (
        <MainNavigation>
            <LogoWrapper to={t('home_canonical_url')}>
                <LogoImage src={logo_full_name} alt="rdct-logo" loading="eager" width="55px" height="55px"/>
            </LogoWrapper>
            <MenuWrapper>
                <SimpleMenuLink to={t('home_canonical_url')}>{t('home_menu')}</SimpleMenuLink>
                <DropdownMenuItem
                    dropdownContent={
                        <DropdownMenuItemContainer>
                            <SubMenuBoxes>
                                <SubMenuBox
                                    description={t('about_menu.description')}
                                    imageSrc={aboutImage}
                                    title={t('about_menu.title')}
                                    toref={t('about_menu.canonical_url')}
                                    alText={t('about_menu.description')}
                                    bgColor={GlobalSettings.mainDarkColor}
                                    titleColor={GlobalSettings.surrogateWhiteColor}
                                    txtColor={GlobalSettings.surrogateWhiteColor}
                                />
                                <SubMenuBox
                                    description={t('history_menu.description')}
                                    imageSrc={solutionsCenterImg}
                                    title={t('history_menu.title')}
                                    toref={t('history_menu.canonical_url')}
                                    alText={t('history_menu.description')}
                                    bgColor={GlobalSettings.mainDarkColor}
                                    titleColor={GlobalSettings.surrogateWhiteColor}
                                    txtColor={GlobalSettings.surrogateWhiteColor}
                                />
                            </SubMenuBoxes>
                        </DropdownMenuItemContainer>
                    }
                    label="RDCT"
                />
                <DropdownMenuItem
                    dropdownContent={
                        <DropdownMenuItemContainer>
                            <SubMenuBoxes>
                                <SubMenuBox
                                    description={t('web_technologies_menu.description')}
                                    imageSrc={TrainingWebDevelopmentFrontendImage}
                                    title={t('web_technologies_menu.title')}
                                    toref={t('web_technologies_menu.canonical_url')}
                                    alText={t('web_technologies_menu.description')}
                                    bgColor={GlobalSettings.mainDarkColor}
                                    titleColor={GlobalSettings.surrogateWhiteColor}
                                    txtColor={GlobalSettings.surrogateWhiteColor}
                                />
                                <SubMenuBox
                                    description={t('digital_marketing_menu.description')}
                                    imageSrc={TrainingDigitalMarketingImage}
                                    title={t('digital_marketing_menu.title')}
                                    toref={t('digital_marketing_menu.canonical_url')}
                                    alText={t('digital_marketing_menu.description')}
                                    bgColor={GlobalSettings.mainDarkColor}
                                    titleColor={GlobalSettings.surrogateWhiteColor}
                                    txtColor={GlobalSettings.surrogateWhiteColor}
                                />
                                <SubMenuBox
                                    description={t('management_menu.description')}
                                    imageSrc={ManagementImage}
                                    title={t('management_menu.title')}
                                    toref={t('management_menu.canonical_url')}
                                    alText={t('management_menu.description')}
                                    bgColor={GlobalSettings.mainDarkColor}
                                    titleColor={GlobalSettings.surrogateWhiteColor}
                                    txtColor={GlobalSettings.surrogateWhiteColor}
                                />
                            </SubMenuBoxes>
                        </DropdownMenuItemContainer>
                    }
                    label={t('research_menu')}
                />
                <DropdownMenuItem
                    dropdownContent={
                        <DropdownMenuItemContainer>
                            <SubMenuBoxes>
                                <SubMenuBox
                                    description={t('training_web_development_frontend_menu.description')}
                                    imageSrc={TrainingWebDevelopmentFrontendImage}
                                    title={t('training_web_development_frontend_menu.title')}
                                    toref={t('training_web_development_frontend_menu.canonical_url')}
                                    alText={t('training_web_development_frontend_menu.description')}
                                    bgColor={GlobalSettings.mainDarkColor}
                                    titleColor={GlobalSettings.surrogateWhiteColor}
                                    txtColor={GlobalSettings.surrogateWhiteColor}
                                />
                                <SubMenuBox
                                    description={t('training_web_development_backend_menu.description')}
                                    imageSrc={TrainingWebDevelopmentBackendImage}
                                    title={t('training_web_development_backend_menu.title')}
                                    toref={t('training_web_development_backend_menu.canonical_url')}
                                    alText={t('training_web_development_backend_menu.description')}
                                    bgColor={GlobalSettings.mainDarkColor}
                                    titleColor={GlobalSettings.surrogateWhiteColor}
                                    txtColor={GlobalSettings.surrogateWhiteColor}
                                />
                                <SubMenuBox
                                    description={t('training_search_engine_optimization_menu.description')}
                                    imageSrc={TrainingSearchEngineOptimizationImage}
                                    title={t('training_search_engine_optimization_menu.title')}
                                    toref={t('training_search_engine_optimization_menu.canonical_url')}
                                    alText={t('training_search_engine_optimization_menu.description')}
                                    bgColor={GlobalSettings.mainDarkColor}
                                    titleColor={GlobalSettings.surrogateWhiteColor}
                                    txtColor={GlobalSettings.surrogateWhiteColor}
                                />
                                <SubMenuBox
                                    description={t('training_digital_marketing_menu.description')}
                                    imageSrc={TrainingDigitalMarketingImage}
                                    title={t('training_digital_marketing_menu.title')}
                                    toref={t('training_digital_marketing_menu.canonical_url')}
                                    alText={t('training_digital_marketing_menu.description')}
                                    bgColor={GlobalSettings.mainDarkColor}
                                    titleColor={GlobalSettings.surrogateWhiteColor}
                                    txtColor={GlobalSettings.surrogateWhiteColor}
                                />
                                <SubMenuBox
                                    description={t('training_microsoft_office_menu.description')}
                                    imageSrc={TrainingMicrosoftOfficeImage}
                                    title={t('training_microsoft_office_menu.title')}
                                    toref={t('training_microsoft_office_menu.canonical_url')}
                                    alText={t('training_microsoft_office_menu.description')}
                                    bgColor={GlobalSettings.mainDarkColor}
                                    titleColor={GlobalSettings.surrogateWhiteColor}
                                    txtColor={GlobalSettings.surrogateWhiteColor}
                                />
                            </SubMenuBoxes>
                        </DropdownMenuItemContainer>
                    }
                    label={t('formations_menu')}
                />
                <SimpleMenuLink to={t('internship_canonical_url')}>{t('internship_program_menu')}</SimpleMenuLink>
                <DropdownMenuItem
                    dropdownContent={
                        <DropdownMenuItemContainer>
                            <SubMenuBoxes>
                                <SubMenuBoxAnchorLink
                                    description={t('clean_agency_menu.description')}
                                    imageSrc={MenuCleanImage}
                                    title={t('clean_agency_menu.title')}
                                    toref="https://clean.rdct.tech"
                                    alText={t('clean_agency_menu.description')}
                                    bgColor={GlobalSettings.mainDarkColor}
                                    titleColor={GlobalSettings.surrogateWhiteColor}
                                    txtColor={GlobalSettings.surrogateWhiteColor}
                                />
                                <SubMenuBoxAnchorLink
                                    description={t('book_sharing_menu.description')}
                                    imageSrc={MenuBookSharingImage}
                                    title={t('book_sharing_menu.title')}
                                    toref="http://booksharing.rdct.tech"
                                    alText={t('book_sharing_menu.description')}
                                    bgColor={GlobalSettings.mainDarkColor}
                                    titleColor={GlobalSettings.surrogateWhiteColor}
                                    txtColor={GlobalSettings.surrogateWhiteColor}
                                />
                            </SubMenuBoxes>
                        </DropdownMenuItemContainer>
                    }
                    label={t('solution_menu')}
                />
                <SimpleMenuLink to={t('consulting_services_menu.canonical_url')}>{t('consulting_services_menu.title')}</SimpleMenuLink>
                <SimpleMenuLink to={t('support_for_visa_menu.canonical_url')}>{t('support_for_visa_menu.title')}</SimpleMenuLink>
                <DropdownMenuItem
                    dropdownContent={
                        <DropdownMenuItemContainer>
                            <SubMenuBoxes>
                                <div><a href="https://g.page/r/CfM7vZT4bsaSEAE?gm"><ContactCard {...contactsData[0]}/></a></div>
                                <ContactCard {...contactsData[1]}/>
                                <ContactCard {...contactsData[2]}/>
                                <ContactCard {...contactsData[3]}/>
                                <ContactCard {...contactsData[4]}/>
                            </SubMenuBoxes>
                        </DropdownMenuItemContainer>
                    }
                    label="Contacts"
                />
                <LanguageButton onClick={() => ToggleLanguage(t('code'))}>{t('code_name')}</LanguageButton>
            </MenuWrapper>
        </MainNavigation>
    );
};

export default HeaderDesktop;