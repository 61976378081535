import styled from 'styled-components';
import GlobalSettings from './common/GlobalSettings';

const ContactCard = ({cardColor, textColor, description, icons}) => {

  const CardBox = styled.div`
    background: ${cardColor};
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    align-content: flex-start;
    border-radius: none;
    padding: ${GlobalSettings.marginAroundHTMLElements};

    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    transition: all 0.2s ease-in-out;
    &:hover{
      transform: scale(1.02);
      transition: all 0.2s ease-in-out;
      cursor: pointer;
    }
  `;

  const TextP = styled.p`
    color: ${textColor};
    padding-left: 5px;
  `;

  return (
    <CardBox>
      {icons}
      <TextP>{description}</TextP>
    </CardBox>
  );
};

export default ContactCard;