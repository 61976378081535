import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import GlobalSettings from './common/GlobalSettings';

const SubMenuBox = ({description, imageSrc, title, toref, alText, bgColor, titleColor, txtColor}) => {

  const MenuBox = styled.div`
    background-color: ${bgColor};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;
    padding: ${GlobalSettings.paddingAroundHTMLElements};
    padding-top: 0px;
    padding-bottom: 0px;

    transition: all 0.2s ease-in-out;
    &:hover{
      transform: scale(1.02);
      border-radius: 5px;
      cursor: pointer;
      opacity: 0.6;
    }
    
    flex-basis: 236px;
  `;

  const Image = styled.img`
    height: 150px;
    width: 100%;
    padding-bottom: ${GlobalSettings.marginAroundHTMLElements};
  `;

  const Title = styled.span`
    color: ${titleColor};
    /* font-family: 'Roboto Flex', 'sans-serif'; */
    /* line-height: 1.2; */
    font-weight: bold;
    padding-top: ${GlobalSettings.marginAroundHTMLElements};
    padding-bottom: ${GlobalSettings.marginAroundHTMLElements};
    /* text-transform: uppercase; */
    font-size: 14pt;
      
    @media screen and (max-width: 1023px) {
      font-size: 12pt;
    }

    @media screen and (max-width: 768px) {
      font-size: 11pt; 
    }

    @media screen and (max-width: 480px) {
      font-size: 10pt;
    }
  `;

  const TextP = styled.p`
    color: ${txtColor};
  `;

  return (
    <MenuBox>
      <Link to={toref}>
        <Image src={imageSrc} alt={alText} loading="eager" width="300px" height="200px"/>
        <Title>{title}</Title>
        <TextP>{description}</TextP>
      </Link>
    </MenuBox>
  );
};

export default SubMenuBox;