import React from "react";
import styled from "styled-components";
import GlobalSettings from '../GlobalSettings';
import * as RiIcons from "react-icons/ri";


const MenuLink = styled.a`
    color: ${GlobalSettings.mainWhiteColor};
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    padding: 10px;
    margin-top: 0;
    margin-bottom: 0;
    text-decoration: none;
    &:hover {
        color: ${GlobalSettings.mainYellowColor};
        cursor: pointer;
    }
`;

const MenuLinkDropdownItem = styled.div`
    background-color: ${GlobalSettings.surrogateDarkColor};
    display: none;
    position: absolute;
    width: 100%;
    left: 0;
    z-index: 1;
    overflow: auto;
`;

const MenuLinkWrapper = styled.div`
    &:hover ${MenuLinkDropdownItem} {
        display: block;
    }
`;

const DropdownMenuItem = ({ dropdownContent, label }) => {
    return (
        <MenuLinkWrapper>
            <MenuLink>
                <span>{label}</span>
                <RiIcons.RiArrowDropDownLine />
            </MenuLink>
            <MenuLinkDropdownItem>{dropdownContent}</MenuLinkDropdownItem>
        </MenuLinkWrapper>
    );
};

export default DropdownMenuItem;